import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";

import Layout from "components/Layout";
import Container from "components/Container";
import AnimatedText from "components/AnimatedText";

import { motion } from "framer-motion";

let images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const item = {
  initial: { y: 50, opacity: 0 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 100,
      stiffness: 200,
      mass: 10
    }
  }
};

const Antiprocess = () => {
  return (
    <Layout pageName='antiprocess'>
      <Helmet>
        <title>Antiprocess</title>
      </Helmet>

      <Container>
        <div className='project-header'>
          <div className='info'>
            <Link className="back" to='/'>Back to projects</Link>
            <AnimatedText>Antiprocess</AnimatedText>
            <div className='details'>
              <motion.div
                initial='initial'
                animate='animate'
                variants={{ animate: { transition: { staggerChildren: 0.1 } } }}
                className='tasks'
              >
                <motion.p variants={item}>Tasks</motion.p>
                <ul>
                  <motion.li variants={item}>Branding,</motion.li>
                  <motion.li variants={item}>Jewelry Design,</motion.li>
                  <motion.li variants={item}>Creative Direction</motion.li>
                </ul>
              </motion.div>
              <div className="further">
                <a href="http://antiprocess.fr">View live</a>
              </div>
            </div>
          </div>
          <motion.img
            magicId='project-image'
            src='/images/antiprocess/antiprocess_highlight.jpg'
            alt=''
          />
        </div>
        <motion.div initial='initial' animate='animate' className='project-images'>
          {images.map(image => {
            return (
              <motion.img
                variants={item}
                src={`/images/antiprocess/antiprocess_${image}.jpg`}
                alt=''
              />
            );
          })}
        </motion.div>

        <p>Welcome to page 2</p>
      </Container>
    </Layout>
  );
};

export default Antiprocess;
